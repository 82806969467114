<template>
  <card-component :title="this.creating ? 'Nieuw Label': 'Wijzig Label'"
                  :icon="this.creating ? 'plus-circle' : 'pencil'"
                  style="min-width: 60vw"
                  :is-scrollable="true">


    <form @submit.prevent="submit">

      <b-field label="Label weergave:" horizontal>
        <b-tag :type="label.color">{{ label.name }}</b-tag>
      </b-field>

      <b-field label="Label naam" horizontal>
        <b-field>
          <b-input v-model="label.name" name="name" required/>
        </b-field>

        <!--        <b-field>-->
        <!--          <b-input v-model="label.type" placeholder="Vlan 2024" name="type"-->
        <!--                   required/>-->
        <!--        </b-field>-->

      </b-field>

      <b-field label="Beschrijving" horizontal>
        <b-field>
          <b-input v-model="label.description" placeholder="Beschrijving" name="beschrijving" />
        </b-field>
      </b-field>

      <b-field label="Kleur" horizontal>
        <b-field>
          <b-select v-model="label.color">
            <option value="is-success">groen</option>
            <option value="is-danger">rood</option>
            <option value="is-info">blauw</option>
            <option value="is-primary">geel</option>
            <option value="is-purple">paars</option>
          </b-select>
        </b-field>

        <b-field label="Snelkoppeling?">
          <b-checkbox v-model="label.selectable">
          </b-checkbox>
        </b-field>
      </b-field>


      <hr>

      <b-field horizontal>
        <b-field grouped position="is-right">
          <div class="control">
            <b-button type="is-primary is-outlined" @click="this.$parent.close">Annuleer</b-button>
          </div>
          <div class="control">
            <b-button  native-type="submit" type="is-primary">{{
                this.creating ? "Voeg toe" : "Bewaar"
              }}
            </b-button>
          </div>
        </b-field>

      </b-field>

    </form>

  </card-component>
</template>

<script>

import CardComponent from "@/components/CardComponent";

export default {
  name: "LabelFormComponent",
  components: {CardComponent},
  props: {
    id: {
      default: null
    },
  },
  data() {
    return {
      label: {},
    }
  },

  computed: {
    creating() {
      return this.id === null
    }

  },

  mounted() {
    if (this.creating) {
      this.label = {
        name: "",
        address: "",
        description: "",

      }
    } else {
      // Fetch vlan data
      this.$store.dispatch('fetchLabelDetails', this.id)
          .then(response => {
            this.label = response.data
          })
    }
  },

  methods: {

    submit() {
      // Add new vlan
      if (this.creating) {
        this.$store.dispatch('createLabel', this.label)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                this.$parent.close()
                this.$buefy.toast.open({
                    message: 'Label created.',
                    type: 'is-success'
                })

            }).catch(err => {
            this.$buefy.toast.open({
                message: err,
                type: 'is-danger'
            })
            })
      }
      // Update existing vlan
      else {
        this.$store.dispatch('updateLabel', {id: this.id, data: this.label})
            .then(response => {
              console.log(response)
                this.$parent.close()
                this.$buefy.toast.open({
                    message: 'Label updated',
                    type: 'is-success'
                })

            }).catch(err => {
            this.$buefy.toast.open({
                message: err,
                type: 'is-danger'
            })
        })
      }

    },

  }

}
</script>

<style scoped lang="scss">


</style>