<template>
  <div class="container px-5" style="max-width: 1000px">

    <card-component :has-title-slot="true" class=" has-table">
      <div slot="title" class="level is-mobile">
        <div class="level-left">
          <a class="level-item" @click="$router.go(-1)">
            <b-icon type="is-grey-darker" icon="arrow-left" class="ml-4"></b-icon>
          </a>
          <p class="level-item title is-5 pl-0">
            Label overzicht
          </p>

            <b-button @click.stop="formModal(null)"
                      type="is-primary is-light"
                      icon-left="plus-circle"
                      size="is-small"
                      class="level-item"
            >
              Nieuw label
            </b-button>

        </div>

      </div>

      <b-table :data="labels"
               paginated
      >

        <b-table-column field="color" label="Kleur" v-slot="props">
          <b-tag :type="props.row.color">---</b-tag>
        </b-table-column>

        <b-table-column field="name" label="Naam" v-slot="props">
          <b>{{ props.row.name }}</b>
        </b-table-column>

        <b-table-column field="description" label="Beschrijving" v-slot="props">
          {{ props.row.description }}
        </b-table-column>

        <!--        <b-table-column field="type" label="Type" v-slot="props">-->
        <!--          {{ props.row.type }}-->
        <!--        </b-table-column>-->

        <!--        <b-table-column field="selectable" label="Snelkoppeling" v-slot="props">-->
        <!--          <b-checkbox disabled v-model="props.row.selectable"></b-checkbox>-->
        <!--        </b-table-column>-->

        <b-table-column field="edit" width="100" v-slot="props">
          <div class="buttons ">
            <b-button icon-left="pencil" size="is-small" type="is-primary"
                      @click="formModal(props.row.id)"></b-button>

            <b-button icon-left="delete" size="is-small" type="is-danger"
                      @click="deleteModal(props.row.id)"></b-button>

          </div>
        </b-table-column>

      </b-table>
    </card-component>
  </div>
</template>

<script>
import LabelFormComponent from "@/components/buildings/LabelFormComponent";
import CardComponent from "@/components/CardComponent.vue";

export default {
  name: "LabelsOverview",
  components: {CardComponent},
  data() {
    return {}
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    labels() {
      return this.$store.state.labels
    }
  },
  methods: {

    fetchData() {
      this.$store.dispatch("fetchAllLabels")
      this.$store.dispatch('setTitle', {
        back: true,
        stack: [
          {
            message: "Labels",
          }
        ]
      })
    },

    formModal(id) {
      this.$buefy.modal.open({
        parent: this,
        component: LabelFormComponent,
        props: {
          id: id
        },
        hasModalCard: true,
        trapFocus: true
      })
    },

    deleteModal(id) {
      this.$buefy.dialog.confirm({
        title: 'Label verwijderen',
        message: 'Weet u zeker dat u dit label wilt <b>verwijderen?</b> Deze actie kan niet ongedaan gemaakt worden. ',
        confirmText: 'Verwijder',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteLabel(id)
      })
    },

    deleteLabel(id) {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch("deleteLabel", id).then(_ => {
        this.$buefy.toast.open('Label verwijderd.')
      })

    }

  }
}
</script>

<style scoped>

.box {
  transition: box-shadow .3s;

}

.box:hover {
  box-shadow: 0 0 12px rgba(33, 33, 33, .2);
}

</style>